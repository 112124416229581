import gql from 'graphql-tag'

/**
 * 搜索股票
 */
export const queryStkAssetInfo = gql`
  query ($filter: FilterStkAssetInfoInput!) {
    stkAssetInfo(filter: $filter) {
      code
      result
      message
    }
  }
`

/**
 * 查询港股行情
 */
export const quotHk = gql`
  query ($filter: FilterStkQuotVOInput!) {
    quotHk(filter: $filter) {
      code
      result
      message
    }
  }
`

/**
 * 查询美股行情
 */
export const quotUs = gql`
  query ($filter: FilterStkQuotVOInput!) {
    quotUs(filter: $filter) {
      code
      result
      message
    }
  }
`
/**
 * 查询A股行情
 */
export const quotA = gql`
  query ($filter: FilterStkQuotVOInput!) {
    quotA(filter: $filter) {
      code
      result
      message
    }
  }
`
/**
 * 获取资金明细/持仓明细
 */
export const fundInfoPositionList = gql`
  query {
    fundInfoList: lpoaFundInfoList {
      data {
        clientid
        moneyType
        asset
        dailyIncome
        fetchBalance
        enableBalance
        marketValue
        info {
          fundAccount
          # assetProp
          moneyType
          exchangeType
          enableBalance
          enableBalanceDisplay
          marketValue
          marketValueDisplay
          # marginValue
          # currentBalance
          # uncomeBuyBalance
          # uncomeSellBalance
          # frozenBalance
          # frozenBalanceDisplay
          # unfrozenBalance
          # checkBalance
          # tradeLimit
          # creditLimit
          # fineIntegralBalance
          # interestIntegralBalance
          # realSellBalance
          # realBuyBalance
          # cashOnHold
          # settdayBalance
          # t1dayBalance
          # ledgerBalance
          # marginCall
          # tradedayBalance
          fetchBalance
          fetchBalanceDisplay
          # netInterestBalance
          # transferBalance
          # settledBalance
          # unsettBalance
          asset
          assetDisplay
          # fundTransferBalance
          # ipoBalance
          # creditRatio
          # ipoDeductBalance
          # positionIncome
          dailyIncome
        }
      }
    }
    positionList: lpoaPositionList {
      data {
        clientid
        marketValueAll
        marketValueHk
        marketValueUs
        marketValueA
        moneyType
        positionIncome
        info {
          rows {
            exchangeType
            stockCode
            clientId
            fundAccount
            moneyType
            stockType
            beginAmount
            currentAmount
            uncomeBuyAmount
            uncomeSellAmount
            uncomeBuyAmountT1
            uncomeSellAmountT1
            uncomeBuyAmountT2
            uncomeSellAmountT2
            uncomeBuyAmountTn
            uncomeSellAmountTn
            frozenAmount
            sumBuyAmount
            sumBuyBalance
            sumSellAmount
            sumSellBalance
            costPrice
            entityAmount
            incomeBalance
            incomeBalanceDisplay
            enableAmount
            enableAmountDisplay
            stockName
            marketValue
            marketValueDisplay
            marginRatio
            marginValue
            realBuyAmount
            realSellAmount
            stockNameBig5
            stockNameGb
            closePrice
            stkcodeStatus
            suspendDays
            greyFlag
            greyMarketDate
            realBuyBalance
            realSellBalance
            currentPriceDisplay
            costPriceDisplay
            incomePercentDisplay
          }
        }
      }
    }
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`
/**
 * 批量交易=>批量买入/卖出
 */
export const placeOrderBatch = gql`
  mutation ($params: PlaceOrderBatchInput!) {
    lpoaPlaceOrderBatch(param: $params) {
      ok
      # data {
      #   taskid {
      #     # managerFundAccount
      #   }
      # }
      reason {
        err
        desc
      }
    }
  }
`

/**
 * 持仓明细
 */

export const positionList = gql`
  query lpoaPositionList {
    lpoaPositionList {
      data {
        clientid
        info {
          rows {
            exchangeType
            stockCode
            clientId
            fundAccount
            moneyType
            stockType
            beginAmount
            currentAmount
            uncomeBuyAmount
            uncomeSellAmount
            uncomeBuyAmountT1
            uncomeSellAmountT1
            uncomeBuyAmountT2
            uncomeSellAmountT2
            uncomeBuyAmountTn
            uncomeSellAmountTn
            frozenAmount
            sumBuyAmount
            sumBuyBalance
            sumSellAmount
            sumSellBalance
            costPrice
            entityAmount
            incomeBalance
            incomeBalanceDisplay
            enableAmount
            enableAmountDisplay
            stockName
            marketValue
            marketValueDisplay
            marginRatio
            marginValue
            realBuyAmount
            realSellAmount
            stockNameBig5
            stockNameGb
            closePrice
            stkcodeStatus
            suspendDays
            greyFlag
            greyMarketDate
            realBuyBalance
            realSellBalance
            currentPriceDisplay
            costPriceDisplay
            incomePercentDisplay
          }
        }
        structuredFundPositionInfo {
          rows {
            exchangeType
            stockCode
            clientId
            fundAccount
            moneyType
            stockType
            beginAmount
            currentAmount
            currentAmountDisplay
            uncomeBuyAmount
            uncomeSellAmount
            uncomeBuyAmountT1
            uncomeSellAmountT1
            uncomeBuyAmountT2
            uncomeSellAmountT2
            uncomeBuyAmountTn
            uncomeSellAmountTn
            frozenAmount
            sumBuyAmount
            sumBuyBalance
            sumSellAmount
            sumSellBalance
            costPrice
            entityAmount
            incomeBalance
            incomeBalanceDisplay
            enableAmount
            enableAmountDisplay
            stockName
            marketValue
            marketValueDisplay
            marginRatio
            marginValue
            realBuyAmount
            realSellAmount
            stockNameBig5
            stockNameGb
            closePrice
            stkcodeStatus
            suspendDays
            greyFlag
            greyMarketDate
            realBuyBalance
            realSellBalance
            currentPriceDisplay
            costPriceDisplay
            incomePercentDisplay
            handDays
            handDaysDisplay
            incomeAcc
            incomeAccDisplay
            openDay
            priceCur
            priceLast
            priceDateCur
            priceDateLast
            pricePct
            ccyRate
          }
        }
        marketValueAll
        marketValueHk
        marketValueUs
        marketValueA
        moneyType
        positionIncome
        marketValueFundHk
        marketValueFundUs 
        positionIncomeFundHk
        incomePercentFundHk
        positionIncomeFundUs 
        incomePercentFundUs 
      }
    }
  }
`

/**
 * 现金明细
 *  */

export const fundInfoList = gql`
  query {
    fundInfoList: lpoaFundInfoList {
      data {
        clientid
        moneyType
        asset
        dailyIncome
        fetchBalance
        enableBalance
        marketValue
        info {
          fundAccount
          assetProp
          moneyType
          exchangeType
          enableBalance
          enableBalanceDisplay
          marketValue
          marketValueDisplay
          marginValue
          currentBalance
          uncomeBuyBalance
          uncomeSellBalance
          frozenBalance
          frozenBalanceDisplay
          unfrozenBalance
          checkBalance
          tradeLimit
          creditLimit
          fineIntegralBalance
          interestIntegralBalance
          realSellBalance
          realBuyBalance
          cashOnHold
          settdayBalance
          t1dayBalance
          ledgerBalance
          marginCall
          tradedayBalance
          fetchBalance
          fetchBalanceDisplay
          netInterestBalance
          transferBalance
          settledBalance
          unsettBalance
          asset
          assetDisplay
          fundTransferBalance
          ipoBalance
          creditRatio
          ipoDeductBalance
          positionIncome
          dailyIncome
        }
      }
    }
  }
`

/**
 * 订单
 */
export const ordersList = gql`
  query {
    ordersList {
      data {
        info {
          currentPage
          totalCount
          rows {
            orderTxnReference
            entrustPrice
            opOrgId
            operatorNo
            opEntrustWay
            orgId
            orderNo
            tradeDate
            entrustNo
            currDate
            currTime
            fundAccount
            clientId
            exchangeType
            seatNo
            stockCode
            entrustBs
            entrustProp
            entrustAmount
            businessAmount
            entrustStatus
            shortsellType
            originFlag
            operatorCheck
            positionStr
            confirmTime
            investorCode
            oddlotStr
            clearDate
            frozenPrice
            orderValidity
            orderValidityDate
            orderExpiryDate
            orderEntrustAmount
            orderEntrustPrice
            orderBusinessAmount
            orderBusinessBalance
            orderUsefulFlag
            allocOrderTxnReference
            remark
            opRemark
            writebackTime
            instructionTxnReference
            strategyStatus
            withdrawAmount
            bcan
            reconfirmType
            sessionType
            f2bFlag
            businessBalance
            lastBusinessTime
            reviewFlag
            fareBalance
            thirdbrokerId
            clordId
            lastFareWay
            entrustBalance
            prevFrozenBalance
            prevSettBalance
            registerAmount
            currActionEntrustNo
            avlActionEntrustNo
            stockName
            stockNameBig5
            stockNameGb
            lastUpdateDate
            lastUpdateTime
            currSerialNo
            moneyType
            entrustPriceDisplay
            lastUpdateDatetime
            businessAmountDisplay
            entrustAmountDisplay
            businessBalanceDisplay
            orderDetail {
              title
              value
              toShow
              expandItems {
                title
                value
                toShow
              }
            }
          }
        }
      }
    }
  }
`
/**
 * 获取设备 id
 */
export const registerDevice = gql`
  mutation ($params: ParamReigsterDeiveInput!) {
    registerDevice(param: $params) {
      data {
        deviceId
        createTime
      }
      ok
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 获取用户信息
 */
export const lpoaManagersBindInfoList = gql`
  query {
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`

/**
 * 账户密码 => 请求 code_type = get_lpoa_phone_mask 获取脱敏手机号 GET_LPOA_PHONE_MASK_ERR = 33142
 * 发送短信验证码 => 请求 code_type = send_lpoa_login_sms 获取短信验证码 SEND_LPOA_LOGIN_SMS_ERR = 35114
 * 验证短信验证码 => code_type = check_lpoa_login_sms 验证短信验证码
 */
export const lpoaLoginManager = gql`
  mutation lpoaLoginManager($params: LpoaManagerLoginInput!) {
    lpoaLoginManager(param: $params) {
      ok
      data {
        manager
        sessionId
        loginIp
        isDelay
      }
      reason {
        err
        desc
      }
    }
  }
`

/**
 * 账户管理=>获取列表
 */
export const lpoaClientList = gql`
  query ($filter: LpoaClientListInput!) {
    lpoaClientList(filter: $filter) {
      data {
        clients {
          clientId
          moneyType
          asset
          dailyIncome
          fetchBalance
          enableBalance
          marketValue
          position {
            exchangeType
            stockCode
            clientId
            fundAccount
            moneyType
            stockType
            beginAmount
            currentAmount
            uncomeBuyAmount
            uncomeSellAmount
            uncomeBuyAmountT1
            uncomeSellAmountT1
            uncomeBuyAmountT2
            uncomeSellAmountT2
            uncomeBuyAmountTn
            uncomeSellAmountTn
            frozenAmount
            sumBuyAmount
            sumBuyBalance
            sumSellAmount
            sumSellBalance
            costPrice
            entityAmount
            incomeBalance
            incomeBalanceDisplay
            enableAmount
            enableAmountDisplay
            stockName
            marketValue
            marketValueDisplay
            marginRatio
            marginValue
            realBuyAmount
            realSellAmount
            stockNameBig5
            stockNameGb
            closePrice
            stkcodeStatus
            suspendDays
            greyFlag
            greyMarketDate
            realBuyBalance
            realSellBalance
            currentPriceDisplay
            costPriceDisplay
            incomePercentDisplay
          }
          fundinfo {
            fundAccount
            # assetProp
            moneyType
            exchangeType
            enableBalance
            enableBalanceDisplay
            marketValue
            marketValueDisplay
            # marginValue
            # currentBalance
            # uncomeBuyBalance
            # uncomeSellBalance
            frozenBalance
            # frozenBalanceDisplay
            # unfrozenBalance
            # checkBalance
            # tradeLimit
            # creditLimit
            # fineIntegralBalance
            # interestIntegralBalance
            # realSellBalance
            # realBuyBalance
            # cashOnHold
            # settdayBalance
            # t1dayBalance
            # ledgerBalance
            # marginCall
            # tradedayBalance
            fetchBalance
            fetchBalanceDisplay
            # netInterestBalance
            # transferBalance
            # settledBalance
            # unsettBalance
            asset
            assetDisplay
            # fundTransferBalance
            # ipoBalance
            # creditRatio
            # ipoDeductBalance
            # positionIncome
            dailyIncome
          }
          structuredFundPosition {
            exchangeType
            stockCode
            clientId
            fundAccount
            moneyType
            stockType
            beginAmount
            currentAmount
            currentAmountDisplay
            uncomeBuyAmount
            uncomeSellAmount
            uncomeBuyAmountT1
            uncomeSellAmountT1
            uncomeBuyAmountT2
            uncomeSellAmountT2
            uncomeBuyAmountTn
            uncomeSellAmountTn
            frozenAmount
            sumBuyAmount
            sumBuyBalance
            sumSellAmount
            sumSellBalance
            costPrice
            entityAmount
            incomeBalance
            incomeBalanceDisplay
            enableAmount
            enableAmountDisplay
            stockName
            marketValue
            marketValueDisplay
            marginRatio
            marginValue
            realBuyAmount
            realSellAmount
            stockNameBig5
            stockNameGb
            closePrice
            stkcodeStatus
            suspendDays
            greyFlag
            greyMarketDate
            realBuyBalance
            realSellBalance
            currentPriceDisplay
            costPriceDisplay
            incomePercentDisplay
            handDays
            handDaysDisplay
            incomeAcc
            incomeAccDisplay
            openDay
            priceCur
            priceLast
            priceDateCur
            priceDateLast
            pricePct
            ccyRate
          }
        }
      }
    }
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
          clientType
        }
      }
    }
  }
`
/**
 * 账户管理=>重新排序
 */
export const lpoaResortBindAccounts = gql`
  mutation ($params: LpoaResortBraccInput!) {
    lpoaResortBindAccounts(param: $params) {
      ok
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 账户管理=>添加备注
 */
export const lpoaUpdateBindAccountsInfo = gql`
  mutation ($params: LpoaUpdateBtraccInfoInput!) {
    lpoaUpdateBindAccountsInfo(param: $params) {
      ok
      reason {
        err
        desc
      }
    }
  }
`
/**
 * 退出登录
 */
export const lpoaLogoutManager = gql`
  mutation {
    lpoaLogoutManager {
      ok
      reason {
        desc
        err
      }
    }
  }
`

/**
 * 用户的历史汇总数据
 */

export const lpoaSummary = gql`
  query lpoaSummary($filter: GetSummaryDataInput!) {
    lpoaSummary(filter: $filter) {
      data {
        managerId
        asset
        dailyIncome
        positionIncome
        fetchBalance
        enableBalance
        marketValue
        childaccountNum
        day
        updatetime
      }
    }
  }
`

/**
 * 查询单个lpoa订单信息
 */

export const lpoaOrderList = gql`
  query lpoaOrderList($filter: GetOrderListInput!) {
    lpoaOrderList(filter: $filter) {
      currentPage
      totalCount
      rows {
        managerId
        createTime
        stockCode
        exchangeType
        entrustBs
        entrustProp
        entrustFundcount
        state
        stockName
        id
        entrustAmount
        entrustPrice
        operatorAccount
        subOrders {
          fundAccount
          id
          externalOrderNo
          reason
          entrustAmount
          entrustPrice
          businessAmount
          businessPrice
          entrustStatus
        }
      }
    }
  }
`
/**
 * 单个撤单
 */
export const lpoaCancelOrder = gql`
  mutation ($params: LpoaCancelOrderBatchInput!) {
    lpoaCancelOrder(param: $params) {
      ok
      data {
        taskid
      }
      reason {
        desc
        err
      }
    }
  }
`
/**
 * 一键撤单
 */
export const lpoaCancelOrderOneKey = gql`
  mutation ($params: LpoaCancelOrderOneKeyInput!) {
    lpoaCancelOrderOneKey(param: $params) {
      ok
      data {
        taskid
      }
      reason {
        desc
        err
      }
    }
  }
`
/* 查询单个lpoa订单信息
 */
export const lpoaOrderInfo = gql`
  query lpoaOrderInfo($filter: InputOrderInfo!) {
    lpoaOrderInfo(filter: $filter) {
      data {
        managerId
        createTime
        stockCode
        exchangeType
        entrustBs
        entrustProp
        entrustFundcount
        state
        stockName
        id
        entrustAmount
        entrustPrice
        extendedTrading
        subOrders {
          fundAccount
          id
          externalOrderNo
          reason
          entrustAmount
          entrustPrice
          businessAmount
          businessPrice
          entrustStatus
        }
      }
    }
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`

/**
 * 汇率
 */
export const lpoaForex = gql`
  query {
    lpoaForex {
      data {
        baseCcy
        ccy
        rate
      }
    }
  }
`
// 获取本地码表 stkUpdateStockList
export const stkUpdateStockALL = gql`
  query (
    $filterHk: FilterStkVersionVOInput!
    $filterUs: FilterStkVersionVOInput!
    $filterA: FilterStkVersionVOInput!
  ) {
    stkHk: stkUpdateStockList(filter: $filterHk) {
      code
      result
      message
    }
    stkUs: stkUpdateStockList(filter: $filterUs) {
      code
      result
      message
    }
    stkA: stkUpdateStockList(filter: $filterA) {
      code
      result
      message
    }
  }
`
// 延长session
export const extendSession = gql`
  mutation {
    extendSession {
      ok
      data {
        expireTime
        sessionId
      }
      reason {
        err
        desc
      }
    }
  }
`
// 查询港和美股A股行情
export const quotHKAndUSAndA = gql`
  query (
    $filterHk: FilterStkQuotVOInput!
    $filterUs: FilterStkQuotVOInput!
    $filterA: FilterStkQuotVOInput!
  ) {
    quotHongkong: quotHk(filter: $filterHk) {
      code
      result
      message
    }
    quotAmer: quotUs(filter: $filterUs) {
      code
      result
      message
    }
    quotA: quotA(filter: $filterA) {
      code
      result
      message
    }
  }
`
// 一键改单
export const lpoaUpdateOrderBatch = gql`
  mutation lpoaUpdateOrderBatch($params: LpoaUpdateOrderBatchInput!) {
    lpoaUpdateOrderBatch(param: $params) {
      ok
      data {
        taskid
      }
      reason {
        desc
        err
      }
    }
  }
`
// 查询改单信息
export const lpoaUpdateOrderTaskInfo = gql`
  query lpoaUpdateOrderTaskInfo($filter: LpoaGetTaskInfoInput!) {
    lpoaUpdateOrderTaskInfo(filter: $filter) {
      ok
      data {
        progress
        state
        info {
          orderNo
          fundAccount
          nameCn
          info {
            ok
            reason {
              desc
              err
            }
            data {
              riskType
              entrustStatus
              checkList {
                tradeLimitCode
                hintValue
                auditValue
                rejectValue
                checkValue
                exceedValue
                riskType
              }
            }
          }
        }
      }
      reason {
        desc
        err
      }
    }
  }
`

export const queryHomeData = gql`
  query queryHomeData(
    $lpoaSummary: GetSummaryDataInput!
    $filterOrderList: GetOrderListInput!
  ) {
    lpoaSummary: lpoaSummary(filter: $lpoaSummary) {
      data {
        managerId
        asset
        dailyIncome
        fetchBalance
        enableBalance
        marketValue
        childaccountNum
        day
        updatetime
        positionIncome
      }
    }
    lpoaOrderList: lpoaOrderList(filter: $filterOrderList) {
      currentPage
      totalCount
      rows {
        managerId
        createTime
        stockCode
        exchangeType
        extendedTrading
        entrustBs
        entrustProp
        entrustFundcount
        state
        stockName
        id
        entrustAmount
        entrustPrice
        operatorAccount
        subOrders {
          fundAccount
          id
          externalOrderNo
          reason
          entrustAmount
          entrustPrice
          businessAmount
          businessPrice
          entrustStatus
        }
      }
    }
    lpoaFundInfoList {
      data {
        clientid
        moneyType
        asset
        dailyIncome
        fetchBalance
        enableBalance
        marketValue
        info {
          fundAccount
          assetProp
          moneyType
          exchangeType
          enableBalance
          enableBalanceDisplay
          marketValue
          marketValueDisplay
          marginValue
          currentBalance
          uncomeBuyBalance
          uncomeSellBalance
          frozenBalance
          frozenBalanceDisplay
          unfrozenBalance
          checkBalance
          tradeLimit
          creditLimit
          fineIntegralBalance
          interestIntegralBalance
          realSellBalance
          realBuyBalance
          cashOnHold
          settdayBalance
          t1dayBalance
          ledgerBalance
          marginCall
          tradedayBalance
          fetchBalance
          fetchBalanceDisplay
          netInterestBalance
          transferBalance
          settledBalance
          unsettBalance
          asset
          assetDisplay
          fundTransferBalance
          ipoBalance
          creditRatio
          ipoDeductBalance
          positionIncome
          todayPositionIncome
          todayPositionIncomeRate
          dailyIncome
        }
      }
    }
    lpoaPositionList {
      data {
        clientid
        marketValueHk
        marketValueUs
        marketValueA
        moneyType
        marketValueAll
        positionIncome
        todayPositionIncome
        todayPositionIncomeRate
        incomePercentFundHk
        positionIncomeFundUs
        info {
          rows {
            exchangeType
            stockCode
            clientId
            fundAccount
            moneyType
            stockType
            beginAmount
            currentAmount
            uncomeBuyAmount
            uncomeSellAmount
            uncomeBuyAmountT1
            uncomeSellAmountT1
            uncomeBuyAmountT2
            uncomeSellAmountT2
            uncomeBuyAmountTn
            uncomeSellAmountTn
            frozenAmount
            sumBuyAmount
            sumBuyBalance
            sumSellAmount
            sumSellBalance
            costPrice
            entityAmount
            incomeBalance
            incomeBalanceDisplay
            enableAmount
            enableAmountDisplay
            stockName
            marketValue
            marketValueDisplay
            marginRatio
            marginValue
            realBuyAmount
            realSellAmount
            stockNameBig5
            stockNameGb
            closePrice
            stkcodeStatus
            suspendDays
            greyFlag
            greyMarketDate
            realBuyBalance
            realSellBalance
            currentPriceDisplay
            costPriceDisplay
            incomePercentDisplay
          }
        }
        structuredFundPositionInfo {
          rows {
            exchangeType
            stockCode
            clientId
            fundAccount
            moneyType
            stockType
            beginAmount
            currentAmount
            currentAmountDisplay
            uncomeBuyAmount
            uncomeSellAmount
            uncomeBuyAmountT1
            uncomeSellAmountT1
            uncomeBuyAmountT2
            uncomeSellAmountT2
            uncomeBuyAmountTn
            uncomeSellAmountTn
            frozenAmount
            sumBuyAmount
            sumBuyBalance
            sumSellAmount
            sumSellBalance
            costPrice
            entityAmount
            incomeBalance
            incomeBalanceDisplay
            enableAmount
            enableAmountDisplay
            stockName
            marketValue
            marketValueDisplay
            marginRatio
            marginValue
            realBuyAmount
            realSellAmount
            stockNameBig5
            stockNameGb
            closePrice
            stkcodeStatus
            suspendDays
            greyFlag
            greyMarketDate
            realBuyBalance
            realSellBalance
            currentPriceDisplay
            costPriceDisplay
            incomePercentDisplay
            handDays
            handDaysDisplay
            incomeAcc
            incomeAccDisplay
            openDay
            priceCur
            priceLast
            priceDateCur
            priceDateLast
            pricePct
            ccyRate
          }
        }
      }
    }
    lpoaForex {
      data {
        rate
        ccy
        baseCcy
      }
    }
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`
// 获取区号
export const lpoaAreaCodes = gql`
  query lpoaAreaCodes {
    lpoaAreaCodes {
      ok
      data {
        options {
          areaCode
          cnName
          flag
          letter
        }
        default {
          areaCode
          cnName
          flag
          letter
        }
      }
      reason {
        desc
        err
      }
    }
  }
`
// 重置密码
export const lpoaResetLpoaPwd = gql`
  mutation lpoaResetLpoaPwd($params: LpoaResetLpoaPwdInput!) {
    lpoaResetLpoaPwd(param: $params) {
      ok
      reason {
        desc
        err
      }
    }
  }
`
// 修改密码
export const lpoaManagerCpwd = gql`
  mutation lpoaManagerCpwd($params: LpoaManagerCpwdInput!) {
    lpoaManagerCpwd(param: $params) {
      ok
      reason {
        desc
        err
      }
    }
  }
`

// // 分仓交易指令
export const queryOrders = gql`
  query queryOrders($filter: GetOrderListInput!) {
    lpoaOrderList: lpoaOrderList(filter: $filter) {
      currentPage
      totalCount
      rows {
        managerId
        createTime
        stockCode
        exchangeType
        extendedTrading
        entrustBs
        entrustProp
        entrustFundcount
        state
        stockName
        id
        entrustAmount
        entrustPrice
        operatorAccount
        subOrders {
          fundAccount
          id
          externalOrderNo
          reason
          entrustAmount
          entrustPrice
          businessAmount
          businessPrice
          entrustStatus
        }
      }
    }
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`
// 时区
export const serverTime = gql`
  query serverTime($params: ServerTimeInput!) {
    serverTime(param: $params) {
      timeStamp
      timeZone
      timeStr
      timeIsDst
    }
  }
`
// 柜台下单记录今日订单
export const lpoaGetTodayAyersOrders = gql`
  query queryTodayAyersOrders($filter: LpoaGetTodayAyersOrdersInput!) {
    lpoaGetTodayAyersOrders: lpoaGetTodayAyersOrders(filter: $filter) {
      ok
      reason {
        desc
        err
      }
      data {
        currentPage
        totalCount
        rows {
          orderTxnReference
          entrustPrice
          orgId
          orderNo
          tradeDate
          entrustNo
          currDate
          currTime
          fundAccount
          clientId
          exchangeType
          seatNo
          stockCode
          entrustBs
          entrustProp
          entrustAmount
          businessAmount
          entrustStatus
          shortsellType
          originFlag
          operatorCheck
          positionStr
          confirmTime
          investorCode
          oddlotStr
          clearDate
          orderValidity
          orderValidityDate
          orderExpiryDate
          orderEntrustAmount
          orderEntrustPrice
          orderBusinessAmount
          orderBusinessBalance
          orderUsefulFlag
          allocOrderTxnReference
          remark
          opRemark
          writebackTime
          instructionTxnReference
          strategyStatus
          withdrawAmount
          bcan
          reconfirmType
          sessionType
          f2bFlag
          businessBalance
          lastBusinessTime
          reviewFlag
          fareBalance
          thirdbrokerId
          clordId
          lastFareWay
          entrustBalance
          prevFrozenBalance
          prevSettBalance
          registerAmount
          currActionEntrustNo
          avlActionEntrustNo
          stockName
          stockNameBig5
          stockNameGb
          lastUpdateDate
          lastUpdateTime
          currSerialNo
          moneyType
          entrustPriceDisplay
          lastUpdateDatetime
          businessAmountDisplay
          entrustAmountDisplay
          businessBalanceDisplay
          orderDetail {
            title
            value
            toShow
            expandItems {
              title
              value
              toShow
            }
          }
          extendedTrading
        }
      }
    }
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`
// 柜台历史成交
export const lpoaGetHisAyersOrders = gql`
  query lpoaGetHisAyersOrders($filter: LpoaGetHisAyersOrdersInput!) {
    lpoaGetHisAyersOrders: lpoaGetHisAyersOrders(filter: $filter) {
      ok
      data {
        currentPage
        totalCount
        rows {
          initDate
          serialNo
          currDate
          currTime
          opOrgId
          userId
          opStation
          opEntrustWay
          businessFlag
          initDateBargain
          bargainNo
          allocationId
          bargainSource
          bargainType
          clientId
          fundAccount
          entrustBs
          exchangeType
          stockCode
          stockType
          stockName
          moneyType
          seatNo
          reportNo
          orderTxnReference
          businessNo
          businessType
          businessPrice
          businessBalance
          reportTime
          businessTime
          actionEntrustNo
          entrustAmount
          businessAmount
          postAmount
          clearBalance
          postBalance
          costType
          accruedInterest
          interestNdays
          tradeDate
          settDate
          settDateFund
          settDateStk
          tradeFeecode
          fareWay
          fare0
          fare1
          fare2
          fare3
          fare4
          fare5
          fare6
          fare7
          fare8
          farex
          brokerCost
          fxMode
          fxRate
          fxMoneyType
          stockMoneyType
          sessionType
          crossTrade
          shortSell
          buyIn
          placing
          disposalSale
          principalTrade
          bargainWay
          custodian
          custodianId
          realStatus
          remark
          clearDate
          valueDate
          deliverStatus
          commission
          stampDuty
          stampDutyTrade
          transactionFee
          transactionLevy
          frcLevy
          platformUsageFee
          payFee
          supervisoryCommissionFee
          transactionActivityFee
          clearingFee
          exchangeFee
          handlingFee
          plFee
          securitiesManagementFee
          transferFee
          secFee
          fareTotal
        }
      }
      reason {
        desc
        err
      }
    }
    lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          englishFirstname
          englishLastname
          nameCn
          remark
          userId
        }
      }
    }
  }
`
// 柜台订单 改单
export const updateTodayAyersOrders = gql`
  mutation updateTodayAyersOrders($params: LpoaUpdateTodayAyersOrderInput!) {
    updateTodayAyersOrders(param: $params) {
      ok
      data {
        orderNo
        riskType
        checkList {
          tradeLimitCode
          hintValue
          auditValue
          rejectValue
          checkValue
          exceedValue
          riskType
        }
        entrustStatus
        attendtion
      }
      reason {
        desc
        err
      }
    }
  }
`
// 柜台订单 撤单
export const cancelTodayAyersOrders = gql`
  mutation cancelTodayAyersOrders($params: LpoaCancelTodayAyersOrderInput!) {
    cancelTodayAyersOrders(param: $params) {
      ok
      reason {
        desc
        err
      }
      data {
        orderNo
        fundAccount
        nameCn
        resp {
          ok
          reason {
            desc
            err
          }
        }
      }
    }
  }
`

// 公告
export const publishedNotices = gql`
  query publishedNotices($filter: OMSFilterpublishedNoticesInput!) {
    publishedNotices(filter: $filter) {
      code
      message
      result {
        id
        title
        content
        clientLocations
        userType
      }
    }
  }
`

// operatorClientEntrustments 根据操作员获取客户委托协议列表
export const operatorClientEntrustments = gql`
  query operatorClientEntrustments($param: LpoaGetClientEntrustAgreementsInput!) {
    operatorClientEntrustments(param: $param) {
      ok
      data {
        clientEntrustments {
          id
          submitTime
          userId
          tradeAccount
          clientNameCn
          clientNameEn
          hasSigned
          clientEntrustmentFile
        }
        total
      }
      reason {
        desc
        err
      }
    } 
  }
`

// 上传客户文件 addClientFile
export const addClientFile = gql`
  mutation addClientFile($param: LpoaAddClientFileInput!) {
    addClientFile(param: $param) {
      ok
      reason {
        desc
        err
      }
      data
    }
  }
`
// 客户文件列表 getClientFiles
export const getClientFiles = gql`
  query getClientFiles($param: LpoaGetClientFilesInput!) {
    getClientFiles(param: $param) {
      ok
      reason {
        desc
        err
      }
      data {
        clientFiles {
          id
          filename
          fileUrl
        }
      }
    }
  }
`

// 发送客户文件 sendClientFile
export const sendClientFile = gql`
  mutation sendClientFile($param: LpoaSendClientFileInput!) {
    sendClientFile(param: $param) {
      ok
      reason {
        desc
        err
      }
    }
  }
`

// 删除客户文件 deleteClientFile
export const deleteClientFile = gql`
  mutation deleteClientFile($param: LpoaDeleteClientFileInput!) {
    deleteClientFile(param: $param) {
      ok
      reason {
        desc
        err
      }
    }
  }
`

// 操作员签署委托协议 operatorSignAgreement
export const operatorSignAgreement = gql`
  mutation operatorSignAgreement($param: LpoaOperatorSignAgreementInput!) {
    operatorSignAgreement(param: $param) {
      ok
      reason {
        desc
        err
      }
    }
  }
`

// 获取客户委托协议文件
export const clientEntrustAgreement = gql`
  query clientEntrustAgreement($param: LpoaGetClientEntrustAgreementInput!) {
    clientEntrustAgreement(param: $param) {
      ok
      reason {
        desc
        err
      }
      data
    }
  }
`

// immTradeRemindInfo  投资移民交易提醒
export const immTradeRemindInfo = gql`
  query immTradeRemindInfo($param: LpoaImmTradeRemindInfoInput!) {
    immTradeRemindInfo(param: $param) {
      ok
      data {
        clientId
        tips
}
      reason {
        desc
        err
      }
    }
  }
`

// getPushMessages
export const getPushMessages = gql`
  query getPushMessages($filter:LpoaGetPushMessages!) {
    getPushMessages(filter:$filter) {
      code
      message
      result
    }
  }
`

// 设置单条消息已读
export const setSingleMsgRead = gql`
  mutation setSingleMsgRead($param: LpoaSetSingleMsgReadInput!) {
    setSingleMsgRead(param: $param) {
      ok
      reason {
        desc
        err
      }
    }
  }
`
